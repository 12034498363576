import { graphql } from "gatsby";
import React, { Component, useState } from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import Responsive from "../components/responsive";
import Seo from "../components/seo";
import { PageProps } from "../shared";
import { green, lilac } from "../shared/colors";

const Wrapper = styled.section`
  display: flex;
  padding-top: 30px;
  flex-direction: column;
  align-items: center;
  ${Responsive};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: rgba(${lilac}, 0.7);
`;

const FormHeader = styled.h2`
  padding: 20px;
`;

type ContactProps = { headerImg: string, formHeader: string, preConsent: string, postConsent: string, };

export const query = graphql`
{
  pageData: contactJson {
    headerImg
    preConsent
    postConsent
    formHeader
  }
}
`;

class Contact extends Component<PageProps<ContactProps>, {}> {
  constructor(props: PageProps<ContactProps>) {
    super(props);
  }

  componentDidMount() {
    const script = document.createElement("script") as HTMLScriptElement;
    script.src = "//js.hsforms.net/forms/shell.js";
    script.async = true;
    script.onload = () => this.scriptLoaded();
    document.body.appendChild(script);
  }

  scriptLoaded() {
    let hbspt = (window as any)?.hbspt;
    if (typeof hbspt != 'undefined') {
      if (typeof hbspt != 'undefined') {
        hbspt.forms.create({
          region: "na1",
          portalId: "7296998",
          formId: "e201fd93-55a9-41f0-919d-6240cc295091",
          target: '#form'
        });
      }
    }
  }

  render() {
    const { headerImg, formHeader } = this.props.data.pageData;

    const handleSubmit = (e: any) => {
    }

    return (
      <Layout header={headerImg} title="Contact" transparent={true}>
        <Seo title='Contact' />
        <Wrapper id="contact">
          <FormHeader dangerouslySetInnerHTML={{ __html: formHeader }}></FormHeader>
          <Form onSubmit={handleSubmit} id="form"></Form>
        </Wrapper>
      </Layout>);
  }
}


export default Contact;